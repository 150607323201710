<template>
  <section>
    <!-- Table Top -->
    <b-sidebar
      id="create-custom-group-sidebar"
      v-model="openSideBar"
      title="Custom Group Properties"
      bg-variant="white"
      right
      shadow
      backdrop
      aria-controls="create-custom-group-sidebar"
      :aria-expanded="openSideBar"
      width="70vw"
      @hidden="onHidden"
    >
      <validation-observer
        ref="createGroupObserver"
      >
        <section>
          <b-card>
            <b-form>
              <b-row>
                <b-col sm="6">
                  <validation-provider
                    v-slot="validationContext"
                    ref="groupName"
                    rules="required"
                    name="Group Name"
                  >
                    <b-form-group
                      label="Group Name"
                      label-for="Group Name"
                    >
                      <b-form-input
                        id="group-name"
                        v-model="groupNameLocal"
                        type="text"
                        placeholder="Group Name"
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    >
                      {{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <b-form-group
                    label="Search"
                    label-for="Search"
                  >
                    <b-input-group
                      class="mb-2"
                    >
                      <b-input-group-prepend class="search-icon">
                        <feather-icon
                          icon="SearchIcon"
                          size="14"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        id="`type-search`"
                        v-model="search"
                        type="search"
                        placeholder="Search"
                        class="pool-search"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <div v-if="isLoading">
                <aom-skeleton-loader />
              </div>
              <b-row>
                <user-list
                  v-model="selectedUsers"
                  :users="participantsList"
                  @userSelected="updateSelectedUsers"
                />
                <custom-groups-list
                  v-model="selectedGroups"
                  :groups="groups"
                  @groupSelected="updateSelectedGroups"
                />
              </b-row>
            </b-form>
          </b-card>
        </section>
      </validation-observer>
      <template #footer="{ hide }">
        <b-row
          v-if="!isLoading"
          class="ml-1 mr-1 pt-1 border-top"
        >
          <b-col
            sm="12"
            md="4"
            class="mb-1"
          >
            <b-button
              block
              type="reset"
              variant="outline-dark"
              @click="hide"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            sm="12"
            md="5"
            class="mb-1"
          >
            <b-button
              type="submit"
              block
              variant="primary"
              @click="updateGroup"
            >
              <feather-icon
                v-if="!isUpdating" 
                size="16"
                icon="SaveIcon"
                class="mr-50"
              />
              <b-spinner
                v-if="isUpdating" 
                small
              />
              <span
                v-if="isUpdating"
              > Updating...</span>
              <span v-else>Update Group</span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-sidebar>
  </section>
</template>
            
<script>
import {
  BRow,
  BCol,
  BSidebar,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCard,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  VBTooltip
} from "bootstrap-vue";
            
import Ripple from 'vue-ripple-directive';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { groupsService, usersService } from '@/services';
import { mapGetters } from 'vuex';
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import _debounce from "lodash/debounce";
import { getValidationState } from "@/libs/utils";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
//eslint-disable-next-line
import { required } from "@validations";
import UserList from './UserList.vue';
import CustomGroupsList from './CustomGroupsList.vue';
  
export default {
  name: 'EditCustomGroup',
  components: {
    BRow,
    BCol,
    BSidebar,
    BButton,
    BSpinner,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    BFormInvalidFeedback,
    BCard,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    AomSkeletonLoader,
    UserList,
    CustomGroupsList
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    groupName: {
      type: String,
      default: ''
    }
  },
  data(vm) {
    return {
      openSideBar: vm.open,
      isLoading: false,
      isUpdating: false,
      participantsList: [],
      selectedUsers: [],
      groups: [],
      selectedGroups: [],
      search: '',
      perPage: 10,
      page: 1,
      columnFilters: [],
      sort: [],
      total: 0,
      groupNameLocal: vm.groupName
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram', 'defaultProgramId']),
    isDisabled () {
      return false;
    }
  },
  watch: {
    async open() {
      try {
        this.openSideBar = this.open;
        this.isLoading = true;
        await Promise.all([
          this.loadParticipants(),
          this.loadGroups(),
          this.loadGroupUsers()
        ]);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    openSideBar(n) {
      if(!n) {
        this.$emit('toggleSideBar', n);
      }
    },
    groupName(n) {
      if(n) {
        this.groupNameLocal = n;
      }
    },
    search: _debounce(function(newVal){
      this.loadLists(newVal);
    }, 1000)
  },
  methods: {
    async loadLists(search) {
      try {
        this.isLoading = true;
        await Promise.all([
          this.loadParticipants(search),
          this.loadGroups(search)
        ]);
      } catch(e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async loadParticipants(search)  {
      const filter = {
        columnFilters: [],
      };
      if(search) {
        filter.columnFilters.push({field: "full_name",value: search});
      }  
              
      try {
        const response = await usersService.getListParticipantsByProgram(this.defaultProgramId, filter);
        const { items }  = response.data;
        this.participantsList = items;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast('Error. Fetching participants list'));
        this.$log.error(e);
      }finally {
      }
    },
    async loadGroups(search) {
      const filter = {
        columnFilters: [],
      };
      if(search) {
        filter.columnFilters.push({field: "name", value: search});
      }  
              
      try {
        const programId = this.defaultProgramId;
        this.groups = [];
        const response = await groupsService.getList(programId, filter);
        const { data } = response;
        this.groups = data.items;
      } catch (e) {
        this.$toast(makeErrorToast('Error. Fetching Pool Groups list'));
        console.log(e);
        this.$log.error(e);
      }finally {
      }
    },
    async updateGroup() {
      const isValid = await this.$refs.createGroupObserver.validate();
      if (!isValid) {
        return;
      }
      try {
        const programId = this.defaultProgramId;
        const groupId = this.$route.params.groupId;
        this.isUpdating = true;
        const data = {
          name: this.groupNameLocal,
          users: this.selectedUsers,
          groups: this.selectedGroups
        };
        const response = await groupsService.updateCustomGroup(programId, groupId, data);
        this.$toast(makeSuccessToast('Custom Group updated successfully.'));
        this.$emit('updated', response);
        this.loadLists();
        this.openSideBar = false;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e.response.data.message));
        this.$log.error(e);
      } finally {
        this.isUpdating = false;    
      }
    },
    async loadGroupUsers() {
      try {
        const programId = this.defaultProgramId;
        const groupId = this.$route.params.groupId;
        const response = await groupsService.getListGroupUsers(programId, groupId);
        const { items } = response.data;
        this.selectedUsers = items.map(i => ({id: i.id}));
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
    updateSelectedGroups (value) {
      this.selectedGroups = [
        ...this.selectedGroups,
        ...value
      ];
    },
    updateSelectedUsers(value) {
      this.selectedUsers = [
        ...this.selectedUsers,
        ...value
      ];
    },
    onHidden() {
      this.openSideBar = false;
      this.isLoading = false,
      this.isUpdating = false;
      this.matchedUsers = [];
      this.selectedMatch = undefined;
      this.poolUsers = [];
      this.selectedUsers = [];
      this.groups = [];
      this.selectedGroups = [];
      this.search = '';
      this.matchesPerMentor = undefined;
      this.$emit('close', true);
    },
    hasUserReachMatchLimit(user) {
      return Boolean(user?.locked_and_active_matches_count > user?.match_limit) || Boolean(Number(user?.match_limit) === 0);
    }
  },
  setup() {
    return {
      getValidationState
    };
  }
};
</script>
            
    <style lang="scss">
        $sidebar-header-text-color: #808080;
        .b-sidebar > .b-sidebar-header{
            flex-direction: row-reverse;
            background: #f3f3f3;
            color: $sidebar-header-text-color;
            #create-custom-group-sidebar___title__ {
                font-size: .8em;
                flex: 2;
            }
        }
        .card {
            box-shadow: none;
        }
        .full-width {
            width: 100%
        }
        .border-top {
            border-top: 1px solid #ccc;
        }
        .list-group-item {
            .form-group {
                flex:2;
                .custom-control-label {
                    width: 100%;
                }
            }
        }
        .search-icon {
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            border-right: none;
            margin-right:-5px;
        }
        .pool-search {
            border-left: none;
        }
    </style>
          