<template>
  <b-col sm="6">
    <h5 class="mt-50 mb-50">
      Groups {{ selectedGroupsCount }}
    </h5>
    <b-row>
      <b-col sm="12">
        <b-form-checkbox
          v-model="isSelectAllGroups"
          class="mt-1 mb-1"
          @change="checkAllGroups()"
        >
          Select All
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div v-if="(groups.length === 0)">
      <h5 class="text-muted text-center mt-3">
        No results found.
      </h5>
    </div>
    <b-list-group
      v-for="(group, index) in groups"
      :key="index"
    >
      <b-list-group-item class="d-flex align-items-center">
        <b-form-group
          v-slot="{ ariaDescribedby }"
          class="mb-0"
        >
          <div class="d-flex">
            <b-form-checkbox-group
              :checked="value"
              :aria-describedby="ariaDescribedby"
              name="selected-group"      
              class="mt-2"
              @input="$emit('input', $event)"
            >
              <b-form-checkbox
                :value="{id: group.id}"
              />
            </b-form-checkbox-group>
            <div
              class="right-flex-1"
            >
              <h5 class="mt-50 mb-0">
                {{ group.name }}
              </h5>
              <p class="text-muted mb-0">
                <small>{{ group.type.name }}</small>
              </p>
            </div>
          </div>
        </b-form-group>
      </b-list-group-item>
    </b-list-group>
  </b-col>
</template>
<script>          

import {
  BRow,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BListGroupItem,
  BListGroup,
} from "bootstrap-vue";
  
export default {
  name: 'CustomGroupsList',
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BListGroupItem,
    BListGroup
  },
  props: {
    groups: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      isSelectAllGroups: false
    };
  },
  computed: {
    selectedGroupsCount () {
      return Number(this.value.length);
    },
  },
  watch: {      
    value: {
      handler(n) {
        if(n && n.length === this.groups.length && n.length > 0 && this.groups.length > 0) {
          this.isSelectAllGroups = true;
        } else {
          this.isSelectAllGroups = false;
        }
      },
      deep: true,
      immediate: true
      
    }
  },
  methods: {
    checkAllGroups() {
      const selected = [];
      if(this.isSelectAllGroups) { 
        this.groups.forEach(group => {
          selected.push({ id: group.id });
        });
      }
      this.$emit('input', selected);
    }
  }
};
</script>