import { render, staticRenderFns } from "./EditCustomGroup.vue?vue&type=template&id=55a84c8d&"
import script from "./EditCustomGroup.vue?vue&type=script&lang=js&"
export * from "./EditCustomGroup.vue?vue&type=script&lang=js&"
import style0 from "./EditCustomGroup.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports