<template>
  <b-card>  
    <vue-good-table
      class="mt-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      :select-options="{ 
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'bulk-selection-wrapper',
        selectionText: 'Participants selected',
        clearSelectionText: 'Clear Selections',
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-selected-rows-change="bulkSelectionChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <!-- <div
          v-if="props.column.field === 'action'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap vgt-center-align"
        >
          <div class="d-flex align-items-center mb-0 mt-0">
            <b-button
              variant="outline-none"
              class="btn-icon"
              :to="{ name: 'admin-program', params: { id: props.row.id } }"
            >
              <feather-icon
                icon="ViewIcon"
                size="20"
              />
            </b-button>
          </div>
        </div> -->
        <!-- Column: Action -->
        <!-- <div
          v-if="props.column.field === 'action'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap vgt-center-align"
        >
          <div class="align-items-center mb-0 mt-0">
            <b-button
              variant="outline-none"
              class="nav action-menu"
            >

              <b-nav-item-dropdown
                class="btn-icon"
              >
                <template 
                  #button-content
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />                
                </template>
                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  :to="{
                    name: 'champion-program-communications',
                    params: { id: defaultProgramId, participantId: props.row.id },
                  }"
                >
                  <feather-icon
                    size="16"
                    icon="MessageSquareIcon"
                    class="mr-50"
                  />
                  <span>Send Message</span>
                </b-dropdown-item>
                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click.prevent="enableNotifications( props.row.id)"
                >
                  <feather-icon
                    size="16"
                    icon="BellIcon"
                    class="mr-50"
                  />
                  <span>Enable Notifications</span>
                </b-dropdown-item>
                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click.prevent="disableNotifications( props.row.id)"
                >
                  <feather-icon
                    size="16"
                    icon="BellOffIcon"
                    class="mr-50"
                  />
                  <span>Disable Notifications</span>
                </b-dropdown-item>
                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click.prevent="suspendUser( props.row.id)"
                >
                  <feather-icon
                    size="16"
                    icon="UserCheckIcon"
                    class="mr-50"
                  />
                  <span>Suspend</span>
                </b-dropdown-item>
                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click.prevent="unSuspendUser( props.row.id, 'unsuspend')"
                >
                  <feather-icon
                    size="16"
                    icon="UserMinusIcon"
                    class="mr-50"
                  />
                  <span>Unsuspend</span>
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-button>

          </div>
        </div> -->
        <div
          v-if="props.column.field === 'full_name'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap"
        >
          <div class="align-items-center mb-0 mt-0">
            <router-link
              :to="{
                name: 'champion-program-participant',
                params: { id: defaultProgramId, participantId: props.row.id },
              }"
            >
              {{ props.row.full_name }}
            </router-link>
          </div>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="
            (value) => props.pageChanged({ currentPage: value })
          "
        />
      </template>
      <div slot="selected-row-actions">
        <b-dropdown
          text="Selected Actions"
          variant="primary"
        >
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="bulkSendMessage"
          >
            <feather-icon
              size="16"
              icon="MessageSquareIcon"
              class="mr-50"
            />
            <span>Send Message</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="bulkEnableNotifications(true)"
          >
            <feather-icon
              size="16"
              icon="BellIcon"
              class="mr-50"
            />
            <span>Enable Notifications</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="bulkEnableNotifications(false)"
          >
            <feather-icon
              size="16"
              icon="BellOffIcon"
              class="mr-50"
            />
            <span>Disable Notifications</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="bulkSuspend"
          >
            <feather-icon
              size="16"
              icon="AlertTriangleIcon"
              class="mr-50"
            />
            <span>Suspend</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="bulkUnSuspend"
          >
            <feather-icon
              size="16"
              icon="AlertTriangleIcon"
              class="mr-50"
            />
            <span>Unsuspend</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="isCustomGroup"
            link-class="d-flex align-items-center"
            @click="bulkRemoveUsersGroup"
          >
            <feather-icon
              size="16"
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Remove from Group</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="saveAsCustomGroup"
          >
            <feather-icon
              size="16"
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Save as Custom Group</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </vue-good-table>
  </b-card>
</template>
    
<script>
import {
  BCard,
  BDropdownItem, 
  BDropdown
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import groupsService from "@/services/groupsService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { participantRolesDisplay, userRoles as userRolesModel, userRolesDisplay, userStatus, groupType } from '@models';
import { mapGetters } from 'vuex';
import usersService  from "@/services/usersService";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import { roleAlias } from "@/@aom-core/utils/utils";

export default {
  name: 'ViewGroupList',
  components: {
    VueGoodTable,
    BCard,
    TablePagination,
    BDropdownItem, 
    BDropdown
  },
  props: {
    groupName: {
      type: String,
      default: ''
    },
    groupTypeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showArchived: true,
      isLoading: false,
      element : { },
      dir: false,
      columns: [],
      columnFilters: [],
      sort: [],
      rows: [],
      bulkSelection: []
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    isCustomGroup() {
      return this.groupTypeId === groupType.CUSTOM;
    }
  },
  created() {
    this.columns = [
      {
        label: "Name",
        field: "full_name",
        filterOptions: {
          enabled: true,
          placeholder: "Name",
        },
        width: "20em",
      },
      {
        label: "Role",
        field: "role",
        filterable: true,
        filterOptions: {
          enabled: true,
          placeholder: "All",
          filterDropdownItems: participantRolesDisplay.map(r => { return {...r, text: roleAlias(r.value, this.defaultProgram)};}),
        },
        width: "10em",
      }
    ];
    this.loadItems();
  },
  methods: {
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
    
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },
    
    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "full_name", 
        "role",
      ]) {
        if (params.columnFilters[col]) {
          if(col === 'role'){
            let roleIds = [];
            roleIds.push(params.columnFilters.role);
            columnFilters.push({
              field: col,
              value: roleIds
            });
          }
          else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const programId = this.defaultProgramId;
        const groupId = this.$route.params.groupId;
        const response = await groupsService.getListGroupUsers(programId, groupId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const {total, items} = response.data;
        this.total = total;
        const mappedItems = items.map(i => i = { 
          ...i,
          role: this.userRole(i?.user_roles)
        });
        this.rows = mappedItems;
      } catch (e) {
        console.log(e);
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    userRole(userRoles) {
      if(userRoles.length > 0) {
        const roles = userRoles
          .filter(r => r.role.id !== userRolesModel.PARTICIPANT_CANDIDATE)
          .map(r => userRolesDisplay[r.role.id]);
        const uniqueRoles = [...new Set(roles)];
        
        return uniqueRoles.map(r => {
          const index = Object.values(userRolesDisplay).findIndex(label => label === r);
          if (index > -1) {
            return roleAlias(Object.keys(userRolesDisplay)[index], this.defaultProgram);
          }
          return r;
        }).join(", ");
      }
      return '';
    },
    async enableNotifications() {
      this.$bvModal
        .msgBoxConfirm("Enabling notifications will cause these participants to receive email notifications. Do you wish to continue?", {
          title: 'Enable Notifications?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Enable',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              this.$toast(makeErrorToast("Not yet implemented"));
            } catch (e) {
              this.$toast(makeErrorToast("Something went wrong. Update not saved"));
              this.$log.error(e);
            } finally {
            }
          }
        });
    },
    async disableNotifications() {
      this.$bvModal
        .msgBoxConfirm("Disabling notifications will cause these participants to not receive email notifications. Do you wish to continue?", {
          title: 'Disable Notifications?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Disable',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              this.$toast(makeErrorToast("Not yet implemented"));
            } catch (e) {
              this.$toast(makeErrorToast("Something went wrong. Update not saved"));
              this.$log.error(e);
            } finally {
            }
          }
        });
    },
    bulkSelectionChange(args) {
      const { selectedRows } = args;
      if(Array.isArray(selectedRows)) {
        this.bulkSelection = selectedRows;
      }
    },
    bulkSendMessage() {
      if(Array.isArray(this.bulkSelection) && this.bulkSelection.length > 0) {
        const users = this.bulkSelection.map(u => u.id);
        this.$router.push({name: 'champion-program-communications', query: {user_ids: users}});
      }
    },
    bulkEnableNotifications(emailNotifications = false) {
      if(Array.isArray(this.bulkSelection) && this.bulkSelection.length > 0) {
        let modalPrompt = emailNotifications ?
          'Enabling notification will cause these participant to receive email notifications. Do you wish to continue?.':
          'Disabling notification will cause these participant to not receive email notifications. Do you wish to continue?';
        let modalTitle = emailNotifications ?
          'Enable Notifications?':
          'Disable Notifications?';
        let modalOkButton = emailNotifications ?
          'Enable':
          'Disable';
        let successMsg = emailNotifications ? 'Enable' : 'Disable';
        this.$bvModal
          .msgBoxConfirm(modalPrompt, {
            title: modalTitle,
            size: 'sm',
            okVariant: 'primary',
            okTitle: modalOkButton,
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if(value) {
              try {
                const programId = this.$route.params.id;
                const users = this.bulkSelection.map(s => ({id: s.id}));
                const data = {
                  email_notifications: Boolean(emailNotifications),
                  users: users
                };
                const result = await usersService.setManyParticipantProgramSettings(programId, data);
                if (result) {
                  this.$toast(makeSuccessToast(`Email Notifications for this program are ${successMsg} for all selected participants. The user will always still receive in platform notifications.`));
                  this.loadItems();
                }
              } catch (e) {
                const { data } = e.response;
                this.$toast(makeErrorToast(data.message));
                this.$log.error(e);
              }
            }
          });
      }
    },
    bulkSuspend() {
      const usersToDelete = this.bulkSelection.filter(s => {
        if(Number(s.locked_and_active_matches_count) === 0) {
          return {
            id: s.id
          };
        }
      });
      if(Number(usersToDelete.length) === 0 ) {
        this.$bvModal
          .msgBoxConfirm('You cannot suspend a participant from a program if they have an active published match.', {
            title: 'Cannot suspend',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'OK',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if(value) {
              return;
            }
          });
      } else if(Array.isArray(this.bulkSelection) && this.bulkSelection.length > 0) {
        this.$bvModal
          .msgBoxConfirm('Are you sure you want to suspend these users? Users with a Published Match cannot be Removed or Suspended and will be ignored.', {
            title: `Suspend ${this.bulkSelection.length} users`,
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Suspend',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if(value) {
              try {
                const programId = this.$route.params.id;
                const usersToSuspend = this.bulkSelection.filter(s => {
                  if(Number(s.locked_and_active_matches_count) === 0) {
                    return {
                      id: s.id
                    };
                  }
                });
                if(usersToSuspend.length === 0) {
                  this.$toast(makeErrorToast('There are no users to Suspend as they all have a Locked or Active Match'));
                  return;
                }
                const data = {
                  status_id: userStatus.SUSPENDED,
                  users: usersToSuspend
                };
                const result = await usersService.setManyParticipantStatus(programId, data);
                if (result) {
                  this.$toast(makeSuccessToast('Bulk suspend successful.'));
                  this.loadItems();
                }
              } catch (e) {
                console.log(e);
                const { data } = e.response;
                this.$toast(makeErrorToast(data.message));
                this.$log.error(e);
              }
            }
          });
      }
    },
    bulkUnSuspend() {
      if(Array.isArray(this.bulkSelection) && this.bulkSelection.length > 0) {
        this.$bvModal
          .msgBoxConfirm('Are you sure you want to unsuspend these users? Users with a Published Match will be ignored.', {
            title: `Suspend ${this.bulkSelection.length} users`,
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Unsuspend',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if(value) {
              try {
                const programId = this.$route.params.id;
                const usersToSuspend = this.bulkSelection.filter(s => {
                  if(Number(s.locked_and_active_matches_count) === 0) {
                    return {
                      id: s.id
                    };
                  }
                });
                if(usersToSuspend.length === 0) {
                  this.$toast(makeErrorToast('There are no users to Unsuspend as they all have a Locked or Active Match'));
                  return;
                }
                const data = {
                  status_id: userStatus.ACTIVE,
                  users: usersToSuspend
                };
                const result = await usersService.setManyParticipantStatus(programId, data);
                if (result) {
                  this.$toast(makeSuccessToast('Bulk Unsuspend successful.'));
                  this.loadItems();
                }
              } catch (e) {
                const { data } = e.response;
                this.$toast(makeErrorToast(data.message));
                this.$log.error(e);
              }
            }
          });
      }
    },
    async bulkRemoveUsersGroup() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you wish to remove participants from this group?', {
          title: 'Remove Participants',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Remove',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              const programId = this.$route.params.id;
              const groupId = this.$route.params.groupId;
              this.isUpdating = true;
              const data = {
                users: this.bulkSelection.map(user => ({id: user.id })),
                groups: [{id: Number(groupId)}],
              };
              await groupsService.patchRemoveUsersGroups(programId, data);
              this.$toast(makeSuccessToast('Bulk Remove successful.'));
              this.loadItems();
            } catch (e) {
              console.log(e);
              const { data } = e.response;
              this.$toast(makeErrorToast(data.message));
              this.$log.error(e);
            }
          }
        });
    },
    async saveAsCustomGroup() {
      try {
        const programId = this.$route.params.id;
        this.isUpdating = true;
        const data = {
          name: `${this.groupName} - Copy`,
          users: this.bulkSelection.map(user => ({id: user.id })),
          groups: []
        };
        await groupsService.createCustomGroup(programId, data);
        this.$toast(makeSuccessToast(`Custom ${this.groupName} - Copy created successfully.`));
        this.$router.push({name: 'champion-participants-groups'});
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e.response.data.message));
        this.$log.error(e);
      } finally {
        this.isUpdating = false;    
      }
    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, perPage, page, roleAlias
    };
  },  
};
</script>
    
<style lang="scss" scoped>
  tbody > tr > td {
    vertical-align: middle !important;
  }
  
  tr > td {
    vertical-align: middle !important;
  }
  
  .badge {
    font-weight: 400 !important;
  }
  .bulk-selection-wrapper {
    background: none !important;
    font-size: 14px;
    color: inherit !important;
  }
  ::v-deep .vgt-selection-info-row{
    background: none;
    font-size: 14px;
    color: inherit;
  }
</style>
    
<style lang="scss">
  @import "/src/assets/scss/vue-good-table.scss";
  .action-menu {
    display: inline-block;
  }
  .nav .dropdown-toggle:not(.active)::after {
    display: none;
  }
</style>
    