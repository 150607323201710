<template>
  <b-col sm="6">
    <h5 class="mt-50 mb-50">
      Participants {{ selectedUsersCount }}
    </h5>
    <b-row>
      <b-col sm="12">
        <b-form-checkbox
          v-model="isSelectAllUsers"
          class="mt-1 mb-1"
          @change="checkAllUsers()"
        >
          Select All
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div v-if="(users.length === 0)">
      <h5 class="text-muted text-center mt-3">
        No results found.
      </h5>
    </div>
    <b-list-group
      v-for="(user, index) in users"
      :key="index"
    >
      <b-list-group-item class="d-flex align-items-center">
        <b-form-group
          v-slot="{ ariaDescribedby }"
          class="mb-0"
        >
          <div class="d-flex">
            <b-form-checkbox-group
              :checked="value"
              :aria-describedby="ariaDescribedby"
              name="selected-match"      
              class="mt-2"
              @input="$emit('input', $event)"
            >
              <b-form-checkbox
                :value="{id: user.id}"
              />
            </b-form-checkbox-group>
            <div class="left-flex mr-1 ml-1">
              <b-avatar
                variant="primary"
                :text="userInitials(user)"
                :src="userProfileImage(user)"
                size="3rem"
                class="left-flex"
              />
            </div>
            <div
              class="right-flex"
            >
              <h5 class="mt-50 mb-0">
                {{ user.full_name }}
              </h5>
              <p class="text-muted mb-0">
                <small>{{ user.email }}</small>
              </p>
              <span
                v-for="(role, i) in user.user_roles"
                :key="i"
                class="mb-0"
              >
                <b-badge
                  variant="secondary"
                  class="mr-50 mb-50"
                >
                  {{ roleAlias(role.role_id, defaultProgram) }}
                </b-badge>
              </span>
            </div>
          </div>
        </b-form-group>
      </b-list-group-item>
    </b-list-group>
  </b-col>
</template>
<script>          

import { userRolesDisplay} from '@models';
import {
  BRow,
  BCol,
  BBadge,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BListGroupItem,
  BListGroup,
  BAvatar
} from "bootstrap-vue";
import { roleAlias } from "@/@aom-core/utils/utils";
import { mapGetters } from 'vuex';

export default {
  name: 'UsersList',
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BListGroupItem,
    BListGroup,
    BAvatar,
    BBadge
  },
  props: {
    users: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      userRolesDisplay,
      isSelectAllUsers: false
    };
  },
  
  computed: {
    ...mapGetters('programs',[ 'defaultProgram']),
    selectedUsersCount () {
      return Number(this.value.length);
    },
  },
  watch: {
    value(n) {
      if(n && n.length === this.users.length) {
        this.isSelectAllUsers = true;
      } else {
        this.isSelectAllUsers = false;
      }
    }
  },
  methods: {
    // @TODO move to useUserIntials method
    userInitials(user) {
      const firstInt = Array.from(user.first_name)[0];
      const lastInt = Array.from(user.last_name)[0];
      return `${firstInt}${lastInt}`;
    },
    userProfileImage(user) {
      return user.profile_image?.url || '';
    },
    checkAllUsers() {
      const selected = [];
      if(this.isSelectAllUsers) { 
        this.users.forEach(user => {
          selected.push({ id: user.id });
        });
      }
      this.$emit('input', selected);
    }
  },
  setup() {
    return {
      roleAlias
    };
  }
};
</script>
<style lang="scss">
  .right-flex {
    overflow: auto;
  }
</style>